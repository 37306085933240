@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: "Inter", system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color: #f2f1f6;
  background-color: transparent;
  width: 100%;
  height: 100%;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --PhoneInputCountryFlag-borderColor: transparent;
  --PhoneInputCountryFlag-backgroundColor--loading: transparent;
  --PhoneInputCountryFlag-height: theme(spacing.6);

  --nextui-divider-opacity: 1;

  --mobile-break-point: 576px;

  --root-padding: theme(spacing.6);
}

.olyn {
  --nextui-border-width-medium: 1px;
}

.country-select-option > .PhoneInputCountryIcon {
  --PhoneInputCountryFlag-height: theme(spacing.6);
  width: var(--PhoneInputCountryFlag-height);
  height: var(--PhoneInputCountryFlag-height);
  overflow: hidden;
  border-radius: 100%;
  box-shadow: none;
}

.country-select-option > .PhoneInputCountryIcon > .PhoneInputCountryIconImg {
  object-fit: cover;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#root {
  width: 420px;
  padding: var(--root-padding);
  box-sizing: content-box;
  pointer-events: all;
  position: static;
}

#root.notIframe {
  width: 100% !important;
  padding: theme(spacing.4);
  position: static;
}

@media (max-width: 575px) {
  #root {
    min-width: 320px;
    width: 100%;
    padding: 0;
    position: fixed;
    top: auto !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
  }
}

@media (min-width: 576px) and (max-width: 640px) {
  #root {
    min-width: 320px;
    width: 100%;
    max-width: 393px;
    padding: 0;
  }
}

@media (max-width: 575px) {
  [data-sonner-toaster] {
    position: absolute !important;
    bottom: 100% !important;
    margin-bottom: 10px;
    z-index: 1 !important;
  }
}

@media (min-width: 576px) {
  [data-sonner-toaster] {
    position: relative !important;
    top: -30px !important;
    bottom: 0 !important;
  }
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: transparent;
    width: 100%;
    height: 100%;
  }
}

.grecaptcha-badge {
  display: none !important;
}

.dot-flashing {
  --dot-size: theme(spacing[0.5]);
  position: relative;
  display: inline-block;
  margin-inline-start: calc(1.5 * var(--dot-size));
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: 5px;
  background-color: currentColor;
  color: currentColor;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: calc(-2 * var(--dot-size));
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: 5px;
  background-color: currentColor;
  color: currentColor;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: calc(2 * var(--dot-size));
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: 5px;
  background-color: currentColor;
  color: currentColor;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0.4;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.ios .text-xs,
.ios .text-sm {
  font-size: 16px;
}